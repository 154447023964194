.topbar {
  padding: 2rem 0;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  transition: all ease-in-out 0.5s;
  backdrop-filter: blur(2rem);
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 20;

  &__wrapper {}

  &__logo {
    height: 9rem;
    width: 9rem;
    transition: all 0.5s;
  }

  &__logo-image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__inner {}

  &__icon-link {
    margin-left: 2rem;
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    color: var(--color__black-2);
    transition: 0.24s;

    &:hover {
      color: var(--color__pink-1)
    }

    &:focus {
      outline: transparent;
    }

    &:focus-visible {
      outline: 1px solid var(--color__pink-1);
    }

    &:active {
      color: var(--color__purple);
    }

    &+& {
      margin-left: 2rem;
    }
  }

  &__icon-svg {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: contain;
    object-position: center;
  }

  &__lang-link {
    padding: 0.25em;
    line-height: 1;
    margin-left: 2rem;
  }
}