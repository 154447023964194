@mixin title ($size) {
  font-family: Nunito, sans-serif;
  font-weight: 700;
  color: var(--color__black-1);
  font-size: $size+rem;
  margin: 0;
}

h1,
.--h1 {
  @include title(3);
  line-height: 1;
}

h2,
.--h2 {
  @include title(2.25);
  line-height: 1.1;
}

h3,
.--h3 {
  @include title(2);
  line-height: 1.25;
}

h4,
.--h4 {
  @include title(1.5);
  line-height: 1.33;
}

h5,
.--lead,
.--h5 {
  font-size: 1.25em;
  line-height: 1.67;
}

h1,
.--h1,
h2,
.--h2,
h3,
.--h3,
h4,
.--h4,
h5,
.--h5,
.--lead {
  &>strong {
    font-weight: inherit;
    color: var(--color__purple);
  }
}

body,
.--text {
  font-family: "Noto sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--color__black-2);
}

a,
.--link {
  font-weight: bolder;
  text-decoration: none;
  transition: 0.25s;
  color: var(--color__pink-1);

  &:hover {
    color: inherit;
  }

  &:focus {
    outline: transparent;
  }

  &:focus-visible {
    text-decoration: underline;
  }

  &:active {
    color: var(--color__purple);
  }
}