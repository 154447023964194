:root {
  --color__back: #ffffff;
  --color__black-0: #000000;
  --color__black-1: #3d3d3d;
  --color__black-2: #828282;
  --color__purple: #8e54e9;
  --color__pink-1: #f20d81;
  --color__pink-2: #dc167a;
  --color__pink-3: #ff0081;
  --color__pink-4: #a42063;
  --color__shadow-1: rgba(220, 22, 122, 0.2);
  --color__shadow-2: rgba(252, 12, 98, 0.25);
  --color__shadow-3: rgba(255, 0, 129, 0.35);
  --color__shadow-4: rgba(0, 0, 0, 0.25);
  --color__grey-1: #cfd7d3;
  --color__grey-2: #e8e8e8;
  --color__grey-3: #d3d3d3;
}