.menu {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {}

  &__link {
    display: inline-block;
    padding: 0 1em;
    height: 100%;
    font-weight: 600;
    line-height: 2.5;
    color: var(--color__black-2);
    transition: 0.24s;

    &.--is-active {
      color: var(--color__black-1);
    }

    &:hover {
      color: var(--color__pink-1);
    }

    &:focus {
      outline: transparent;
    }

    &:focus-visible {
      text-decoration: underline;
    }

    &:active {
      color: var(--color__purple);
    }
  }
}