@include font-face("NotoSans-Black", "NotoSans-Black", 400);
@include font-face("NotoSans-BlackItalic", "NotoSans-BlackItalic", 400);
@include font-face("NotoSans-Bold", "NotoSans-Bold", 400);
@include font-face("NotoSans-BoldItalic", "NotoSans-BoldItalic", 400);
@include font-face("NotoSans-ExtraBold", "NotoSans-ExtraBold", 400);
@include font-face("NotoSans-ExtraBoldItalic", "NotoSans-ExtraBoldItalic", 400);
@include font-face("NotoSans-ExtraLight", "NotoSans-ExtraLight", 400);
@include font-face("NotoSans-ExtraLightItalic", "NotoSans-ExtraLightItalic", 400);
@include font-face("NotoSans-Italic", "NotoSans-Italic", 400);
@include font-face("NotoSans-Light", "NotoSans-Light", 400);
@include font-face("NotoSans-LightItalic", "NotoSans-LightItalic", 400);
@include font-face("NotoSans-Medium", "NotoSans-Medium", 400);
@include font-face("NotoSans-MediumItalic", "NotoSans-MediumItalic", 400);
@include font-face("NotoSans-Regular", "NotoSans-Regular", 400);
@include font-face("NotoSans-SemiBold", "NotoSans-SemiBold", 400);
@include font-face("NotoSans-SemiBoldItalic", "NotoSans-SemiBoldItalic", 400);
@include font-face("NotoSans-Thin", "NotoSans-Thin", 400);
@include font-face("NotoSans-ThinItalic", "NotoSans-ThinItalic", 400);
@include font-face("Nunito-Black", "Nunito-Black", 400);
@include font-face("Nunito-BlackItalic", "Nunito-BlackItalic", 400);
@include font-face("Nunito-Bold", "Nunito-Bold", 400);
@include font-face("Nunito-BoldItalic", "Nunito-BoldItalic", 400);
@include font-face("Nunito-ExtraBold", "Nunito-ExtraBold", 400);
@include font-face("Nunito-ExtraBoldItalic", "Nunito-ExtraBoldItalic", 400);
@include font-face("Nunito-ExtraLight", "Nunito-ExtraLight", 400);
@include font-face("Nunito-ExtraLightItalic", "Nunito-ExtraLightItalic", 400);
@include font-face("Nunito-Italic", "Nunito-Italic", 400);
@include font-face("Nunito-Light", "Nunito-Light", 400);
@include font-face("Nunito-LightItalic", "Nunito-LightItalic", 400);
@include font-face("Nunito-Medium", "Nunito-Medium", 400);
@include font-face("Nunito-Regular", "Nunito-Regular", 400);
@include font-face("Nunito-SemiBold", "Nunito-SemiBold", 400);
@include font-face("Nunito-SemiBoldItalic", "Nunito-SemiBoldItalic", 400);
@include font-face("Nunito-MediumItalic", "Nunito-MediumItalic", 400);
