.method {
  padding: 2.25rem 1.5rem;
  border-radius: 0.5rem;
  background-color: var(--color__back);
  background: linear-gradient(0deg, var(--color__back), var(--color__back)),
    linear-gradient(180deg, var(--color__back) 0%, var(--color__grey-1) 100%);
  box-shadow: 0 0.5rem 3rem var(--color__shadow-4);

  &__content {
    flex: 0 1 100%;
  }

  &__title {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.33;
    color: var(--color__black-1);
  }

  &__text {
    margin-top: 0.5rem;
    font-weight: 400;
    line-height: 1.6;
    color: var(--color__black-2);
  }

  &__icon {
    flex: 0 0 auto;
  }

  &__img {}
}